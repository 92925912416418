/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionProducts } from 'interfaces/page-section-products'
import FeaturedProduct from 'components/featured-product'
import { IProductPageKit } from 'interfaces/product-page-kit'
import { JAR_PILL_VARIANT_REGEXP } from 'lib/util/sku-querying'
import useAssignedShopifyProductOfMultipleMedicines from 'hooks/useAssignedShopifyProductOfMultipleMedicines'
import { IMedicine } from 'interfaces/medicine'

interface IProps {
    section: IPageSectionProducts
    scroll?: boolean
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-3.25xl font-medium leading-120%">{children}</h2>
    ),
}

const ProductsPageSection = ({ section, scroll }: IProps): React.ReactElement => {
    const { header, productsCollection } = section

    const products = productsCollection.items
    const allMedicines = new Map<string, IMedicine>()

    products.map((product) => {
        const productChild = product.child as IProductPageKit
        productChild.medicinesCollection.items.map((medicine) => {
            if (!allMedicines.has(medicine.sys.id)) {
                allMedicines.set(medicine.sys.id, medicine)
            }
        })
    })

    const { assignedProductsWithLatestShopifyDetails, loading: isFetchingLatestShopifyDetails } =
        useAssignedShopifyProductOfMultipleMedicines({ medicines: Array.from(allMedicines.values()) })

    let productsWithLatestShopifyDetails = products

    if (assignedProductsWithLatestShopifyDetails) {
        productsWithLatestShopifyDetails = productsWithLatestShopifyDetails.map((product) => {
            const updatedProduct = { ...product }
            const productChild = updatedProduct.child as IProductPageKit
            const medicines = productChild.medicinesCollection.items

            if (medicines.length) {
                productChild.medicinesCollection.items = medicines.map((medicine) => {
                    // add new shopify details
                    return {
                        ...medicine,
                        shopifyProduct: assignedProductsWithLatestShopifyDetails.find((shopifyProduct) =>
                            shopifyProduct.tags.find(
                                (tag) =>
                                    tag.toLowerCase() ===
                                    `sku-identifier-${medicine.medicineSkuIdentifier.toLowerCase()}`,
                            ),
                        ),
                    }
                })
            }

            return updatedProduct
        })
    }

    const primaryProducts = [productsWithLatestShopifyDetails[0], productsWithLatestShopifyDetails[1]].filter(Boolean)
    const secondaryProducts = [productsWithLatestShopifyDetails[2], productsWithLatestShopifyDetails[3]].filter(Boolean)

    // if primary product goes out of stock, swap to the secondary product
    primaryProducts.forEach((product, index) => {
        const productChild = product.child as IProductPageKit
        const allBundleMedicines = productChild.medicinesCollection.items

        const availableBundleMedicines = allBundleMedicines.filter((medicine) => {
            const jarVariantOfMedicine = medicine.shopifyProduct?.variants?.edges?.find(({ node }) =>
                JAR_PILL_VARIANT_REGEXP.test(node?.sku),
            )

            return jarVariantOfMedicine?.node?.availableForSale
        })

        if (availableBundleMedicines.length !== allBundleMedicines.length) {
            if (secondaryProducts[index]) {
                primaryProducts[index] = secondaryProducts[index]
            }
        }
    })

    return (
        <section className="section--v2">
            {/* header */}
            {header?.json && (
                <ContentfulRichText content={header.json} renderNode={headerRenderNode} ignoreMarkdownStyles />
            )}

            {/* products */}
            {primaryProducts.length > 0 && (
                <div
                    className={`mt-9 grid gap-5 ${
                        scroll
                            ? `grid-flow-col overflow-auto grid-cols-[repeat(var(--totalCards),minmax(300px,90%))] md:grid-cols-2 -mx-5 px-5 sm:-mx-6 sm:px-6`
                            : 'grid-cols-1 lg:grid-cols-2'
                    }`}
                    style={{
                        ['--totalCards' as string]: primaryProducts.length,
                    }}
                >
                    {primaryProducts.map((product) => {
                        return (
                            <FeaturedProduct
                                key={product.sys.id}
                                product={product}
                                loading={isFetchingLatestShopifyDetails}
                            />
                        )
                    })}
                </div>
            )}
        </section>
    )
}

export default ProductsPageSection
